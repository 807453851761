.mainSec {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 13px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-radius: inherit;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  -ms-border-radius: inherit;
  -o-border-radius: inherit;
}

.video {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.slick-list,
.slick-track {
  height: 100%;
  * {
    height: 100%;
  }
}

.meet-team {
  position: absolute;
  color: white;
  left: 2rem;
  display: flex;
  width: fit-content;
  align-items: center;
  bottom: 1.5rem;
  gap: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;
}
.meet-team:hover {
  color: #76a3fc;
}

.meet-team svg {
  position: relative;
  display: flex;
  align-items: center;
  width: 1.5rem;
  top: 0.15rem;
  animation: movingRight 0.8s linear infinite;
}

@keyframes movingRight {
  0% {
    right: 0;
  }
  50% {
    right: -0.2rem;
  }
  100% {
    right: 0;
  }
}
