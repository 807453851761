@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sofia&display=swap");

@layer components {
  .container {
    @apply w-[100%] px-[10px] mx-auto lg:w-[90%] lg:px-[30px];
  }

  .element-center {
    @apply flex justify-center items-center;
  }

  .btn {
    @apply bg-gradient-to-b bg-[#3F67AD] hover:from-blue-500 transition-all duration-200 text-white rounded-[12px] px-[15px] py-[10px] sm:px-[28px] sm:py-[15px] font-medium text-[14px] sm:text-lg leading-[26px] not-italic;
  }

  .bfr {
    @apply before:relative before:top-[2px] before:-left-[15px];
  }
}

/* *::-webkit-scrollbar {
  background-color: white;
  width: 5px;
} */

*::-webkit-scrollbar-thumb {
  background-color: #101f2e;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

body::-webkit-scrollbar {
  background-color: #e5eef7;
  width: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #2e497a;
}

/* [#3F67AD]  */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Plus Jakarta Sans",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.ant-btn-primary {
  color: #fff;
  background-color: #1677ff !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.popover {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.popover-content {
  max-width: 200px;
}

.popover-trigger {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: context-menu;
}

/* updates */
.headLeft .ant-btn {
  padding: 16px 20px !important;
}
.headLeft .ant-btn:hover {
  color: #3f67ad !important;
  border: 1px solid #3f67ad;
}

/* .shareFrom .ant-form-item-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.shareFrom .ant-form-item-label {
  text-align: start !important;
}
.shareFrom .ant-form-item-label > label::after {
  display: none;
} */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.ant-input-group-addon .ant-btn-primary {
  background: #3f67ad !important;
}

.loginPage .ant-spin,
.signup .ant-spin {
  max-height: 100vh !important;
}

.signup .ant-spin-dot,
.loginPage .ant-spin-dot {
  position: fixed !important;
}

.add-project-modal .ant-modal-content {
  min-height: calc(100vh - 20px) !important;
}

.ant-tooltip-inner {
  width: fit-content;
}

.text-red {
  color: red !important;
  background-color: white;
}
